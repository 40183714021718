<template>
  <div class="match">
    <div class="banner">
      <img :src="info ? info.banner : ''" />
      <div class="dingwei" @click="jump('matchRanking')" v-if="info && info.status ==3"></div>
      <div class="dingwei" @click="jump('matchForm')" v-else></div>
    </div>
    <div class="nav_wrap">
      <a id="navtop"></a>
    <div :class="scroll>=nav_top && $route.name=='matchIndex'?'nav navposetion':'nav'">
      <ul>
        <li @click="jump('matchIndex')">{{ info ? info.title : "" }}</li>
        <li><a @click="point('top')">奖项设置</a></li>
        <li><a @click="point('guize')">参赛须知</a></li>
        <!-- <li @click="download">模板下载</li> -->
        <li @click="jump('matchRanking')" v-if="info && info.status ==3">获奖公示</li>
        <li @click="jump('matchForm')" v-else>
          作品提交
        </li>
        
        <li @click="jump('matchWorks')">我的作品</li>
      </ul>
    </div>
    </div>
    <div class="wrap">
      <router-view ref="nextPage"></router-view>
    </div>

    <!--漂浮图片-->
    <div class="float_left" v-show="scroll>750">
      <div class="float_div">
        <div class="float_div_text">倒计时</div>
        <div :class="info.countdown>0?'float_div_day':'float_div_day float_div_day2'">{{info && info.countdown==0?'已结束':info.countdown}}</div>
        <div class="float_div_unit" v-if="info && info.countdown>0">天</div>
      </div>
      <div class="float_div_title" @click="jump('matchRanking')" v-if="info && info.status==3">作品公示</div>
      <div class="float_div_title" @click="jump('matchForm')" v-else>上传作品</div>
    </div>

    <div class="float_right" v-show="scroll>700">
      <h2>大赛交流群</h2>
      <div class="float_right_img">
        <img src="../../assets/images/match/code.png" />
      </div>
      <div class="float_right_text">
      
        赛事咨询及大赛交流群<br />
        扫码咨询字体管理员
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "match",
  data() {
    return {
      info: {
        status:1,
        countdown:0
      },
      events_id: "",
      days:'',
      today:'',
      scroll:0,
      nav_top:1000
    };
  },
  mounted() {
    this.$parent.routerIndex = 7;
    this.events_id = 1;
    this.getInfo();
    window.addEventListener('scroll', this.scrollTop)

  },
  methods: {
    scrollTop(){
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;

    },
    getInfo() {
      this.$api.POST(this.$face.match_info, { id: this.events_id }, (res) => {
        this.info = res.data;
        var date = new Date();
        var year = date.getFullYear(); 
        var month = date.getMonth() + 1;
        var day = date.getDate();
        month = month > 9 ? month : "0" + month;
        day = day < 10 ? "0" + day : day;
        this.today = year + "-" + month + "-" + day;
        this.info.days=this.DateDiff(this.today,this.info.end_time.substring(0,10))+1;
        this.height=setInterval(()=>{
            var odiv = document.getElementById('navtop');
            this.nav_top = this.getDistanceOfLeft(odiv).top-69;
        },50)
      });
    },
    jump(page) {
      if(this.$route.name=='matchIndex'){
         let userQuery = {
          events_id: this.events_id
        };
      this.$router.push({
          name: "refresh",
          query: {
            usernext: page,
            refresh: true,
            query: userQuery,
          },
        });
      }else{
this.$router.push({
        name: page,
        query: {
          events_id: this.events_id,
        },
      });
      }
      
     
    },
    download() {
      window.open(this.info.event_file);
    },
    point(e) {
      if (this.$route.name == "matchIndex") {
        this.gundong(e);
      } else {
        this.$router.push({
          name: "matchIndex",
          query: {
            events_id: this.events_id,
            anchor: e,
          },
        });
      }
    },
    gundong(e) {
      var odiv = document.getElementById(e);
      let s = this.getDistanceOfLeft(odiv).top;
      console.log(s);
      let space = 50;
      if (top >= 1000 && top < 2000) {
        space = 50;
      } else if (top >= 2000 && top < 3000) {
        space = 80;
      } else if (top >= 3000 && top < 4000) {
        space = 110;
      } else if (top >= 4000 && top > 6000) {
        space = 150;
      } else if (top >= 6000 && top > 10000) {
        space = 180;
      }
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      if(s<top){
         const timeTop = setInterval(() => {
        let height = (top -= space);
        if (height < s) {
          height = s;
        }
        document.body.scrollTop = document.documentElement.scrollTop = height;
        if (top <= s) {
          clearInterval(timeTop);
        }
      }, 10);

      }else{
        const timeTop = setInterval(() => {
        let height = (top += space);
        if (height > s) {
          height = s;
        }
        document.body.scrollTop = document.documentElement.scrollTop = height;
        if (top >= s) {
          clearInterval(timeTop);
        }
      }, 10);
      }
      
    },
    gundong2(e) {
      var odiv = document.getElementById(e);
      let s = this.getDistanceOfLeft(odiv).top;
      console.log(s);
      if(e=='guize'){
        s=6745;
      }
      if(e=='top'){
        s=2384;
      }
      document.body.scrollTop = document.documentElement.scrollTop = s;
    },
    DateDiff(sDate1, sDate2) {
      //sDate1和sDate2是2006-12-18格式
      var aDate1, aDate2, oDate1, oDate2, iDays;
      aDate1 = sDate1.split("-");
      oDate1 = new Date(aDate1[1] + "/" + aDate1[2] + "/" + aDate1[0]);
      aDate2 = sDate2.split("-");
      oDate2 = new Date(aDate2[1] + "/" + aDate2[2] + "/" + aDate2[0]);
      iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24);
      return iDays;
    },
    getDistanceOfLeft(obj) {
      var left = 0;
      var top = 0;
      while (obj) {
        left += obj.offsetLeft;
        top += obj.offsetTop;
        obj = obj.offsetParent;
      }
      return {
        left: left,
        top: top,
      };
    },
  },
  watch: {
     "$route.query.anchor": function () {
      if(this.$route.query.anchor){
        setTimeout(()=>{
          this.gundong2(this.$route.query.anchor);
        },100)
        
      }
      
     },
     "$route.name": function () {
      if(this.$route.name!='matchIndex'){

        setTimeout(()=>{
          this.gundong2('forms2');
        },10)
      }
      
     },
     "nav_top":function(){
      if(this.nav_top>0){
        clearInterval(this.height);
      }
     }

  },
  activated() {
    console.log(this.scroll);
    this.$nextTick(() => {
      this.$parent.routerIndex = 7;
    })
  }
};
</script>

<style scoped>
.match {
  background-color: #fff;
}
.banner {
  width: 100%;
}
.banner img {
  width: 100%;
}
.nav_wrap{height: 78px; width: 100%; overflow: hidden; position: relative;}
.nav_wrap #navtop{ position: absolute;top: 0;}
.nav {
  height: 78px;
  width: 100%;
  background: url(../../assets/images/match/nav_bg.jpg) no-repeat;
  background-size: 100% 100%;
   overflow: hidden;
}
.nav ul {
  margin: auto;
  width: 1266px;
  height: 78px;
  padding: 0 82px;
  background: url(../../assets/images/match/nav2.jpg) no-repeat;
   overflow: hidden;
}
.nav ul li {
  float: left;
  font-size: 28px;
  text-align: center;
  line-height: 78px;
  cursor: pointer;
  width: 20%;
}
.nav ul li:nth-child(1){ width: 21.5%;}
.nav ul li:nth-child(2){ width: 19.3%;}
.nav ul li:nth-child(3){ width: 19.4%;}
.nav ul li:nth-child(4){ width: 19.48%;}
.nav ul li:nth-child(5){ width: 19.48%;}
.nav ul li a {
  color: #000;
}

.navposetion{ position: fixed; top: 70px; left: 0; z-index: 999;min-width:1430px;;}
.float_left {
  position: fixed;
  top: 35%;
  left: 0;
  width: 178px;
  height: 258px;
  z-index: 9;
  background: #3ad9ff;
  border-radius: 15px;
}
.float_div {
  width: 145px;
  height: 145px;
  background: #b1f504;
  border-radius: 15px;
  margin: 47px auto 0;
  position: relative;
  font-weight: bold;
}
.float_div_text {
  position: absolute;
  left: 16px;
  height: 40px;
  width: 110px;
  line-height: 40px;
  top: -20px;
  background: #eb88f4;
  border-radius: 50%;
  text-align: center;
  font-size:20px;
  font-weight: lighter;
   font-weight: bold;
}
.float_div_day {
  font-size: 60px;
  text-align: center;
  padding-top: 45px;
}
.float_div_unit {
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 16px;
   font-weight: bold;
}
.float_div_title {
  font-size: 28px;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}
.float_right {
  position: fixed;
  top: 35%;
  right: 0;
  width: 178px;
  height: 258px;
  z-index: 9;
  background: #3ad9ff;
  border-radius: 15px;
}
.float_right h2{ font-size: 22px; text-align: center; margin-top: 22px;}
.float_right_img {
  margin: 12px auto 5px;
}
.float_right_img img {
  margin: auto;
  width: 130px;
}
.float_right_text {
  font-size: 14px;
  line-height: 25px;
  text-align: center;
}
.banner{ position: relative;}
.banner .dingwei{width: 140px; height: 140px; border-radius: 50%; position: absolute; top: 57.7%; left: 60.3%; cursor: pointer;}
.float_div_day2{ font-size: 30px;padding-top: 60px;}
</style>